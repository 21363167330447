document.querySelectorAll(".item").forEach(item => {
    item.addEventListener("click", menu)
})

window.onscroll = function() {
    if (document.querySelector(".navbar-wrap").classList.contains("mainpage")) {
        if (window.pageYOffset > 50) {
            document.querySelectorAll(".link").forEach(item => {
                item.classList.add("link--alt")
            })
            document.querySelectorAll(".item").forEach(item => {
                item.classList.add("item--alt")
            })
            document.querySelector(".navbar-wrap").classList.add("navbar-wrap--solid")
            document.querySelector(".navbar__logo").src ="dist/img/kley_logo_alt.svg"
        } else if (document.querySelector(".menu").classList.contains("menu--open") == false) {
            document.querySelectorAll(".link").forEach(item => {
                item.classList.remove("link--alt")
            })
            document.querySelectorAll(".item").forEach(item => {
                item.classList.remove("item--alt")
            })
            document.querySelector(".navbar-wrap").classList.remove ("navbar-wrap--solid")
            document.querySelector(".navbar__logo").src = "dist/img/kley_logo.svg"
        }
    }
}

function menu() {
    if (document.querySelector(".menu").classList.contains("menu--open") == false && window.innerWidth < 768) {
        document.querySelector(".menu").classList.add("menu--open")
        document.querySelector(".navcloser").classList.remove("hidden")
        document.querySelector(".navbar-wrap").classList.add("navbar-wrap--solid")
        document.querySelectorAll(".link").forEach(item => {
            item.classList.add("link--alt")
        })
        document.querySelectorAll(".item").forEach(item => {
            item.classList.add("item--alt")
        })
        document.querySelector(".navbar__logo").src = "dist/img/kley_logo_alt.svg"
    } else if (document.querySelector(".menu").classList.contains("menu--open") && window.pageYOffset < 50 && document.querySelector(".navbar-wrap").classList.contains("mainpage")) {
        document.querySelector(".menu").classList.toggle("menu--open")
        document.querySelector(".navcloser").classList.toggle("hidden")
        document.querySelector(".navbar-wrap").classList.toggle("navbar-wrap--solid")
        document.querySelectorAll(".link").forEach(item => {
            item.classList.toggle("link--alt")
        })
        document.querySelectorAll(".item").forEach(item => {
            item.classList.toggle("item--alt")
        })
        document.querySelector(".navbar__logo").src = "dist/img/kley_logo.svg"
    } else {
        document.querySelector(".menu").classList.remove("menu--open")
        document.querySelector(".navcloser").classList.add("hidden")
    }
}

document.querySelector(".navcloser").addEventListener("click", menu)
document.querySelector(".burger").addEventListener("click", menu)
